@import "https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,400;0,700;1,400;1,700&family=Averia+Serif+Libre:ital,wght@0,300;0,700;1,300;1,700&family=Caveat&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Spectral:ital,wght@0,300;0,600;1,300;1,600&display=swap&family=Noto+Sans+Symbols+2&display=swap";
* {
  box-sizing: border-box;
  text-transform: lowercase;
  margin: 0;
  padding: 0;
}

:root {
  --fg: #0e0e0e;
  --bg: #fefefe;
  --form-bg: #fbfbfb;
  --form-fg: #00004d;
  --form-fg-placeholder: #00004d9a;
}

@media (prefers-color-scheme: dark) {
  :root {
    --fg: #fefefe;
    --bg: #0e0e0e;
    --form-bg: #1a1a1a;
    --form-fg: #fefefe;
    --form-fg-placeholder: #fefefe9a;
  }
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media (width <= 600px) {
  html {
    font-size: 56%;
  }
}

body {
  background-color: var(--bg);
  color: var(--fg);
  counter-reset: sidenote-counter;
  font-family: Averia Serif Libre, serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 300;
  line-height: 2.2rem;
}

article, main {
  width: 100%;
  max-width: 76rem;
  margin: auto;
  padding: 0 1rem;
  position: relative;
}

@media (width <= 600px) {
  article, main {
    padding: 0 .2rem;
  }
}

header, section, footer {
  margin: .7rem;
  padding: .7rem;
}

@media (width <= 600px) {
  header, section, footer {
    margin-top: .2rem;
    margin-bottom: .2rem;
  }
}

header > :last-child, section > :last-child, footer > :last-child {
  margin-bottom: 0;
}

header {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (width <= 600px) {
  header {
    margin: 0;
    padding: 1.2rem;
  }
}

footer {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

p {
  margin: 1.6rem 0;
}

a {
  color: var(--fg);
}

b, strong {
  font-weight: 600;
}

i, em {
  font-size: calc(1em + .1rem);
}

h1, h2, h3, h4 {
  color: var(--fg);
  margin: 1.6rem 0;
  font-family: Averia Libre, cursive;
  font-style: normal;
  font-weight: 600;
}

h1 {
  text-transform: uppercase;
  font-size: 3.6rem;
  line-height: 3.3rem;
}

h2 {
  margin: 1.2rem 0;
  font-size: 2.25rem;
}

h3 {
  font-size: 2.025rem;
}

h3:before {
  content: "🙛 ";
  font-family: "Noto Sans Symbols 2", sans-serif;
}

h4 {
  font-size: 2.025rem;
  font-style: italic;
}

header h1 {
  color: var(--fg);
  text-align: center;
  margin-bottom: 1rem;
  padding: 4rem 0 1.2rem;
  font-size: 4rem;
}

header h1:before, header h1:after {
  content: "✦";
  color: var(--fg);
  vertical-align: middle;
  padding: 0 .5rem;
  font-size: 1.5rem;
}

header nav ul, footer nav ul {
  text-align: center;
  margin-top: 1rem;
  padding-inline-start: 0;
  list-style-type: none;
}

header nav ul li, footer nav ul li {
  margin: 0 1.2rem;
  display: inline;
}

header nav ul li a, footer nav ul li a {
  color: var(--fg);
  text-decoration: none;
}

header nav ul li a:before, footer nav ul li a:before {
  content: "❯ ";
  opacity: 0;
}

header nav ul li a:hover:before, footer nav ul li a:hover:before {
  opacity: 1;
}

:where(main ol, main ul) {
  margin-inline-start: 0;
  margin: .8rem 0;
  padding-inline-start: 3rem;
}

dl {
  margin: .8rem 0;
}

dd {
  margin: 0 1.6rem;
}

img {
  max-width: 100%;
  height: auto;
  margin: .8rem 0;
}

figure img {
  margin: 0;
  padding: 0;
}

figure {
  text-align: center;
  margin: 2rem 0;
  padding: 0;
}

figure * {
  margin-left: auto;
  margin-right: auto;
}

figure code, figure pre {
  text-align: left;
}

figcaption {
  margin: .8rem 0;
  font-size: 1.8rem;
}

form {
  background-color: var(--form-bg);
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: .8rem 0;
  padding: 1rem;
  display: grid;
  box-shadow: 0 0 10px #0000001a;
}

form input[type="email"], form input[type="number"], form input[type="password"], form input[type="search"], form input[type="tel"], form input[type="text"], form input[type="url"], form label, form fieldset, form textarea {
  grid-column: span 2;
}

form input, form button, form textarea {
  resize: vertical;
  width: 100%;
}

input[type="radio"], input[type="checkbox"] {
  width: auto;
  margin-right: .5rem;
}

input[type="radio"] + label, input[type="checkbox"] + label {
  margin-right: 2rem;
}

input[type="radio"]:disabled + label, input[type="checkbox"]:disabled + label {
  opacity: .5;
  text-decoration: line-through;
}

input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], textarea, select {
  appearance: none;
  box-shadow: none;
  box-sizing: inherit;
  color: var(--form-fg);
  border: none;
  border-bottom: 1px solid var(--fg);
  caret-color: var(--form-fg);
  background-color: #0000;
  border-radius: 0;
  margin-bottom: 1.6rem;
  padding: .4rem 1rem;
  font-family: Caveat, cursive;
  font-size: 2.6rem;
}

input[type="email"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="url"]:disabled, textarea:disabled, select:disabled {
  border-bottom: 1px dashed var(--fg);
  text-decoration: line-through;
}

input::placeholder, textarea::placeholder {
  color: var(--form-fg-placeholder);
}

input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, textarea:focus, select:focus {
  border-bottom: 2px solid var(--fg);
  outline: none;
  margin-bottom: calc(1.6rem - 1px);
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  color: var(--fg);
  border: 1px solid var(--fg);
  cursor: pointer;
  background-color: #0000;
  margin-top: 1.6rem;
  padding: 10px;
}

fieldset {
  border: 1px solid var(--fg);
  width: fit-content;
}

form fieldset {
  width: 100%;
}

button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
  opacity: .5;
  cursor: not-allowed;
  border-style: dashed;
  text-decoration: line-through;
}

table {
  border-spacing: 0;
}

td, th {
  padding: .4rem 1rem;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

th {
  border-bottom: 2px solid var(--fg);
  text-align: left;
}

blockquote {
  width: fit-content;
  max-width: 80%;
  margin: 2rem auto;
  padding: 1rem 1.6rem;
  overflow-y: hidden;
}

blockquote p {
  margin: 2.1rem 0;
  font-family: Spectral, serif;
  font-size: 2.1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 2.4rem;
}

blockquote footer {
  float: right;
  text-align: right;
  width: 55%;
  margin: 0;
  padding: 0;
  font-family: Spectral, serif;
  font-size: 1.4rem;
  font-style: normal;
}

blockquote footer cite {
  font-style: italic;
}

pre:has(code) {
  border-top: 2px solid var(--fg);
  border-bottom: 2px solid var(--fg);
  margin: 1.6rem 0;
  padding: 1rem 1.6rem;
  font-family: Courier Prime, monospace;
  font-size: 1.6rem;
  overflow-y: hidden;
}

code {
  font-family: Courier Prime, monospace;
  font-size: 1.6rem;
}

pre .line-number {
  float: left;
  text-align: right;
  border-right: 1px solid;
  margin: 0 1.5rem 0 -1.5rem;
}

pre .line-number span {
  padding: 0 .8rem 0 1.6rem;
  display: block;
}

.sidenote, aside {
  float: right;
  clear: right;
  border-left: 3px solid var(--fg);
  width: 40%;
  margin: 1rem 1rem 1rem 3rem;
  padding: .5rem .5rem .5rem 2rem;
  font-size: 1.4rem;
  line-height: 1.3;
}

aside h1, aside h2, aside h3, aside h4 {
  margin-top: 0;
}

@media (width >= 1160px) {
  .sidenote {
    --distance-from-main: 3rem;
    --self-width: min(calc((100vw - 760px) / 2 - (var(--distance-from-main))), 40%);
    width: var(--self-width);
    margin: 0;
    margin-right: calc(calc(var(--self-width)  + var(--distance-from-main)) * -1);
    border: none;
    padding: 0;
  }
}

.sidenote-anchor {
  counter-increment: sidenote-counter;
}

.sidenote-anchor:after, .sidenote:before {
  font-family: Spectral, serif;
  font-size: 1.3rem;
  position: relative;
}

.sidenote-anchor:after {
  content: counter(sidenote-counter);
  top: -.5rem;
  left: .1rem;
}

.sidenote:before {
  content: counter(sidenote-counter) " ";
  top: -.5rem;
}
/*# sourceMappingURL=index.bb56a841.css.map */
